import { CONSTANTS, KIOSK_DEFAULT } from "../constants";

const CommManager = {
  executeMessage: (store, event) => {
    
    if ( typeof event === 'undefined' || !event.data) {
      return false;
    }

    let dataJSON = {};
    const events = CONSTANTS.EVENTS_GMAPI;

    try {
      dataJSON = JSON.parse(event.data);
    } catch(e) {
      // Not an issue
      return false;
    }

    // console.log("MSG TO WIDGET:", dataJSON);

    // Check configuration message
    if (dataJSON.configData && dataJSON.configData == "clientOrigin") {
      const primaryFrame = store.getters.getPrimaryFrame;
      const primaryFrameSource = primaryFrame.contentWindow;
      if (primaryFrameSource == event.source) {
        dataJSON.origin = event.origin;
        store.dispatch("setPrimaryFrameOrigin", dataJSON.origin);
      }
    }

    const allowEvent = events.find((event) => event === dataJSON.msgId);
    
    if(allowEvent) {
      store.dispatch("setMessageReceived", dataJSON);
    } 
    
    if(store.state.launchingData.channel.includes(KIOSK_DEFAULT)) {
      let dataToSend = {};
      const targetURL = document?.referrer;
      if(event.source === window.parent) { // MESSAGE TO GAME
        dataToSend = { 
          toFrame: 'primary', 
          event: dataJSON.msgId, 
          value: dataJSON.value 
        };
        CommManager.sendMessage(store, dataToSend);
      } else if(targetURL) { // MESSAGE TO PARENT
        dataToSend = {
          msgId: dataJSON.msgId,
          value: dataJSON.value
        };
        window.parent.postMessage(JSON.stringify(dataToSend), targetURL);
      }
    }
  },

  // listener from GmApi
  startMessagesListener: (store) => {
    if (window.addEventListener) {
      window.addEventListener('message', (event) => {
        CommManager.executeMessage(store, event);
      }, false);
    }
  },

  stopMessagesListener: (store) => {
    if (window.addEventListener) {
      window.removeEventListener('message', (event) => {
        CommManager.executeMessage(store, event);
      }, false);
    }
  },

  // Send message to Game (Primary Iframe)
  sendMessage: (store, { toFrame, event, value = null }) => {
    // Support for 3 windows
    const primaryFrame = store.getters.getPrimaryFrame;
    const primaryFrameSource = primaryFrame?.contentWindow;
    const windowPrimaryFrameOrigin = store.getters.getPrimaryFrameOrigin;
    const windowTarget = (!toFrame || toFrame.toLowerCase() == "primary") ? primaryFrameSource: false; // : secondaryFrameSource;
    const windowOrigin = (!toFrame || toFrame.toLowerCase() == "primary") ? windowPrimaryFrameOrigin : false; // windowSecondaryFrameOrigin;

    if (windowTarget && windowOrigin) {
      const jsonData = {
        msgId: event,
        value: value
      };

      // console.log("MSG TO GAME:", jsonData);
      
      windowTarget.postMessage(JSON.stringify(jsonData), windowOrigin);

    } else if (windowTarget) {
      // Resend the message if 1 second (iframe not loaded yet)
      setTimeout(() => {
        CommManager.sendMessage(store, { toFrame, event, value });
      }, 1000);
    }
  },

  getLaunchData: () => {
    const origin = window.origin;
    const location = window.location;
    const paramsUrl = location && location.search;
    const searchParams = new URLSearchParams(paramsUrl);
    const channel = searchParams.get('channel') || '';
    const currency = searchParams.get('currency') || '';
    const gameId = searchParams.get('gameId') || '';
    const gameMode = searchParams.get('gameMode') || '';
    const locale = searchParams.get('locale') || '';
    const luid = searchParams.get('LUID') || '';
    const music = searchParams.get('music') || '';
    const pathLauncher = searchParams.get('pathLauncher') || '';
    const sfx = searchParams.get('sfx') || '';
    const siteId = searchParams.get('siteId') || '';
    const token = searchParams.get('token') || '';
    const userId = searchParams.get('userId') || '';
    const lobbyUrl = searchParams.get('lobbyURL') || '';
    const timedActions = searchParams.get('timedActions') || '';
    const layout = searchParams.get('layout') || '';

    const launchingData = {
      channel,
      currency,
      gameId,
      gameMode,
      locale,
      location,
      luid,
      music,
      origin,
      paramsUrl,
      pathLauncher,
      searchParams,
      sfx,
      siteId,
      token,
      userId,
      lobbyUrl,
      timedActions,
      layout
    };

    return launchingData;
  },
};

export default CommManager;