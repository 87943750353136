const english = {
  keepOnScreen: 'KEEP ON SCREEN',
  helpTitle: 'HELP',
};

const spanish = {
  keepOnScreen: 'MANTENER EN PANTALLA',
  helpTitle: 'AYUDA',
};

const portuguese = {
  keepOnScreen: 'MANTER NA TELA',
  helpTitle: 'AJUDA',
};

const languages = [
  { id: 'en', texts: english },
  { id: 'es', texts: spanish },
  { id: 'pt', texts: portuguese },
  { id: 'pt_br', texts: portuguese }
];

export default languages;